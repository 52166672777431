
import Vue from "vue";
import * as api from "@/api";
import { uploadFile } from "@/utils/upload-file";

export default Vue.extend({
  data() {
    const validatePass = (rule, value, callback) => {
      const self = this as any;
      if (self.form.modified === "") {
        callback(new Error("请输入密码"));
      } else {
        if (self.form.modified !== "") {
          (this.$refs.form as any).validateField("checkPassword");
        }
        callback();
      }
    };
    const validatePass2 = (rule, value, callback) => {
      const self = this as any;
      if (self.confirmed === "") {
        callback(new Error("请再次输入密码"));
      } else if (self.confirmed !== self.form.modified) {
        callback(new Error("两次输入密码不一致!"));
      } else {
        callback();
      }
    };
    return {
      imgUploadUrl: `${process.env.VUE_APP_FILEURL}api/v1.0/File`,
      form: {
        current: "",
        modified: ""
      },
      confirmed: "",
      rules: {
        current: [{ required: true, message: "请输入旧密码", trigger: "blur" }],
        password: [{ validator: validatePass, trigger: "blur" }],
        checkPassword: [{ validator: validatePass2, trigger: "blur" }]
      },
      uploadableExtensions: ["png", "jpeg", "jpg"]
    };
  },
  computed: {
    userInfo() {
      return this.$store.state.userInfo;
    }
  },
  created() {
    this.$store.commit("breadcrumbList", [
      {
        name: "账户设置",
        path: "account"
      }
    ]);
  },
  async mounted() {
    await this.$store.dispatch("getSessionId");
  },
  methods: {
    cancel() {
      this.form = {
        current: "",
        modified: ""
      };
      this.confirmed = "";
    },
    save() {
      (this.$refs.form as any).validate(async valid => {
        if (valid) {
          const res = await api.putPassword(this.form);
          if (!api.error(res)) {
            this.$message.success("保存成功");
          } else {
            this.$message.error(res.error.message);
          }
        } else {
          console.log("error submit!!");
          return false;
        }
        return true;
      });
    },
    uploadModel(_file) {
      const file = _file.raw;
      const index = file.name.lastIndexOf(".");
      const type: string = file.name.slice(index + 1);
      if (!this.uploadableExtensions.some(v => v === type.toLowerCase())) {
        this.$message.warning(`暂时不支持该格式, 请上传${this.uploadableExtensions.join(" ")}文件`);
        return;
      }
      const setFile = async data => {
        if (data.filePath) {
          this.userInfo.avatar = process.env.VUE_APP_BORON + "/" + data.filePath;
          this.$store.commit("setUser", this.userInfo);
          const opt = {
            avatar: process.env.VUE_APP_BORON + "/" + data.filePath
          };
          await api.putAvatar(opt);
        }
      };
      uploadFile(file, setFile, this.$store.state.sessionId);
    }
  }
});
